[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--greencontrast);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding:15px 15px 15px;
  --amplify-components-button-primary-background-color: var(--greencontrast); //done
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--greencontrast);
  --amplify-components-tabs-item-active-border-color: var(--greencontrast); //done
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80); //done
  --amplify-components-tabs-item-active-color: var(--greencontrast);
  --amplify-components-button-link-color: var(--greencontrast);
  --amplify-components-heading-color: var(--white);

  [data-amplify-router] {
      margin: 0 15px 0 15px;
  }
}

#AdminDashboard {
  padding: 60px 10px 100px 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  div.amplify-flex.federated-sign-in-container {
    display: none;
  }

  div.amplify-tabs__list.amplify-tabs__list--top.amplify-tabs__list--equal {
    display: none;
  }
}

#heading {
  span {
    text-transform: uppercase;
  }

  h1 {
    color: var(--greencontrast);
  }
}

.wrapper {
  padding: 15px 15px;
  background-color: rgba(7, 116, 7, 0.107);
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid green;
  border-radius: 15px;
}

#confirmedBookings {
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 23px;
    line-height: 34px;
  }

  .filter {
    input {
      box-sizing: border-box;
      width: 100%;
      padding: 5px;
      margin-top: 5px;
    }
  }

  table {
    font-family: Montserrat, sans-serif;

    td {
      border: 1px solid var(--greencontrast);
      padding: 10px;
      text-align: center;
    }

    .timeSlot {
      align-content: center;
    }

    .phoneNumber {
      width: 60%;
    }
  }
}

#manualBookings {
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 23px;
    line-height: 34px;
  }

  .time {
    width: 100%;
  }

  .date, .time, .name, .phone {
    display: flex;
    gap: 10px;

    input {
      width: 100%;
      padding: 3px;
    }

    span {
      align-content: center;
    }
  }

  button {
    background-color: var(--greencontrast);
    border: 1px solid var(--greencontrast);
    border-radius: 5px;
    color: var(--white);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 20px;
    text-transform: uppercase;
  }
}

#sales {
  .wrapper{
    display: flex;
    flex-direction: row;

    .salesDivision{
      width: 50%;

      .salesHeading{
        span {
          text-transform: uppercase;
        }

        h1 {
          text-transform: uppercase;
          font-size: 23px;
          line-height: 25px;
          padding-bottom: 10px;
        }
      }

      table {
        border: 1px solid var(--greencontrast);

        th, td {
          border-right: 1px solid var(--greencontrast);
          align-content: center;
          text-align: center;
        }

        th {
          font-family: Montserrat, sans-serif;
          text-transform: uppercase;
          font-size: 16px;
          padding: 5px 5px 0 5px;
        }

        td {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 23px;
          line-height: 34px;
          color: var(--greencontrast);
        }
      }
    }

    .totalSold {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 50%;

      .soldHeading {
          font-family: Montserrat, sans-serif;
          text-transform: uppercase;
          font-size: 16px;
      }

      .soldNumbers {
        background-color: var(--greencontrast);
        font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 23px;
          line-height: 34px;
          color: var(--white);
        padding: 18px;
        border-radius: 40px;
      }
    }
  }
}

#bill {
  .wrapper {
    gap: 0;

    span {
      text-transform: uppercase;
    }
  
    h1 {
      font-size: 23px;
      line-height: 25px;
      padding-bottom: 10px;
      text-transform: uppercase;
    }

  }

  .billDivision {
    display: flex;
    align-items: center;
    padding: 10px 0;

  }

  .invoice {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 10px;

    .billedSlots {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 25px;
      line-height: 34px;
      color: var(--greencontrast);
      align-self: center;
    }

    .billedAt {
      text-transform: capitalize;
      align-self: center;
      padding-bottom: 10px;
    }

    button {
      border: 1px solid var(--greencontrast);
      border-radius: 5px;
      color: var(--greencontrast);
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 600;
      padding: 5px 20px;
      text-transform: uppercase;
    }
  }

  .invoiceAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 50%;
    border-left: 1px solid var(--greencontrast);
    
    button {
      width: 75%;
      background-color: var(--greencontrast);
      border: 1px solid var(--greencontrast);
      border-radius: 5px;
      color: var(--white);
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 600;
      padding: 5px 0px;
      text-transform: uppercase;
    }
  }
}