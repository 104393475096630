.ATimeSlot {
    display: flex;
    justify-content: center;
    border: solid 1px var(--greencontrast);
    color: var(--black);
    border-radius: 15px;
    padding: 5px;

    &:hover {
      border: solid 1px var(--greencontrast);
      color: var(--white);
      background-color: var(--greencontrast);
    }
  }

.selected {
  border: solid 1px var(--greencontrast);
  color: var(--white);
  background-color: var(--greencontrast);
}

#timeSlots {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}